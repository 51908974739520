import type { InjectionKey } from "vue";

import type { DtoProduct } from "@/services/api";
import { DiyPlans } from "@/types/returns";
import { getSecondLevelDomain } from "@/util/helpers";

export const API_BASE: string = import.meta.env.VITE_API_BASE_PATH;
export const DEFAULT_TITLE: string = import.meta.env.VITE_BRAND;

export const ADDRESS_REGEXP: RegExp = /^[A-Za-z0-9]( ?[A-Za-z0-9-/])*$/;
export const CITY_REGEXP: RegExp = /^([A-Za-z] ?)*[A-Za-z]$/;
export const IRS_NAME_REGEXP: RegExp = /^[A-Za-z]( |\u003c)?(([A-Za-z#\\-]|\u0026)( |\u003c)?)*([A-Za-z#\\-]|\u0026)$/i;
export const SSN_REGEXP: RegExp = /^(?!000|666)[0-9]{3}([ -]?)(?!00)[0-9]{2}\1(?!0000)[0-9]{4}$/;
export const TIN_REGEXP: RegExp = /^[0-9]{2}([ -]?)[0-9]{7}$/;
export const FULL_NAME_REGEXP: RegExp = /^[A-Za-z]{2,} [A-Za-z]{2,}(?: [A-Za-z0-9]{2,})*$/;
export const FIRST_NAME_REGEXP: RegExp = /^[a-zA-Z]+$/;
export const LAST_NAME_REGEXP: RegExp = /^[a-zA-Z]+(?:-[a-zA-Z]+)?(\s?[a-zA-Z]*){0,2}(\d)?\.?$/;

export const currentYear = new Date().getFullYear();
export const taxYear = currentYear - 1;

export const INJECTION_KEYS = Object.freeze({
  dfyOfferClick: Symbol("dfy-offer-click") as InjectionKey<() => void>, // typed provide/inject example for parent/child components
  updateTargetLocalStorage: Symbol("update-target-local-storage") as InjectionKey<() => void> // typed provide/inject example on app level (global, components not parent/child)
});

export enum Engine {
  FileSmart = "filesmart",
  FileTax = "filetax",
  FileDirect = "filedirect"
}

export enum SupportHref {
  FileSmart = "helpdesk.filesmart.tax",
  FileTax = "helpdesk.file-tax.net",
  Stage = "stage.helpdesk.file-tax.net"
}

export const offerSKU = Object.freeze({
  of00: "of00",
  of02: "of02",
  of04: "of04",
  of05: "of05",
  of12: "of12",
  of16: "of16",
  of19: "of19",
  of18: "of18",
  of21: "of21",
  of25: "of25",
  of29: "of29",
  of30: "of30",
  of31: "of31",
  of32: "of32",
  of33: "of33",
  of34: "of34",
  of35: "of35",
  of36: "of36",
  of37: "of37",
  of99: "of99"
});

export enum ProductCode {
  returnDfyStandard = "return-dfy-standard",
  returnDfySelfemployed = "return-dfy-selfemployed",
  returnDfyBusinessPlatinum = "return-dfy-business-platinum",
  returnDfySelfemployedState = "return-dfy-selfemployed-state",
  returnDfyStandardState = "return-dfy-standard-state",
  returnDfyInvestorState = "return-dfy-investor-state",
  returnDfyBusinessGold = "return-dfy-business-gold",
  returnDfyInvestor = "return-dfy-investor"
}

export const productTags = Object.freeze({
  dfyFullPayment: "dfy-full-payment",
  personalReturn: "personal-return",
  businessReturn: "business-return",
  dfyDeposit: "dfy-deposit",
  dfyFinalPayment: "dfy-final-payment",
  fsCancellationFlow: "fs-cancellation-flow"
});

export const UNRECOVERABLE_ERRORS_WITHOUT_TITLE: string[] = [
  "F7004-011-03",
  "R0000-194",
  "R0000-903-01",
  "IND-900",
  "R0000-902-01"
];

export const multistepOfferSKUs: string[] = [offerSKU.of29, offerSKU.of30, offerSKU.of31, offerSKU.of32, offerSKU.of33];

export const isStage = () => {
  return ["d.file-tax.net", "d.filesmart.tax", "localhost:8080"].includes(window.location.host);
};

export const isPosthogEnabled = () => {
  return import.meta.env.VITE_POSTHOG_KEY && import.meta.env.VITE_POSTHOG_HOST && import.meta.env.MODE === "production";
};

export const personalDeadline = import.meta.env.VITE_PERSONAL_DEADLINE || "";
export const personalExtendedDeadline = import.meta.env.VITE_PERSONAL_EXTENSION_DEADLINE || "";
export const businessDeadline = import.meta.env.VITE_BUSINESS_DEADLINE || "";
export const businessCCorpDeadline = import.meta.env.VITE_BUSINESS_C_CORP_DEADLINE || "";
export const businessSCorpDeadline = import.meta.env.VITE_BUSINESS_S_CORP_DEADLINE || "";
export const businessLLCDeadline = import.meta.env.VITE_BUSINESS_LLC_DEADLINE || "";
export const businessTrustDeadline = import.meta.env.VITE_BUSINESS_TRUST_DEADLINE || "";
export const businessExtendedDeadline = import.meta.env.VITE_BUSINESS_EXTENSION_DEADLINE || "";

export const cookieNames = Object.freeze({
  productSelected: "product-selected",
  redtrackClickId: "rtkclkid",
  redtrackCompanyId: "rtkcmpid",
  refId: "ref_id",
  msClickId: "msclkid",
  formShortCode: "sc",
  utmSource: "utm_source",
  stickyBar01Used: "sb01-used",
  stickyBar02Used: "sb02-used",
  stickyBar03Used: "sb03-used",
  posthogOverrides: "posthog-overrides",
  lastPassedTrafficSource: "last-passed-traffic-source",
  funnel: "funnel",
  userUid: "user-uid",
  currentForm: "current-form",
  deviceId: "device-id",
  userData: "user-data",
  invoiceId: "invoice-id",
  cartData: "cart-data",
  authTokenExp: "ses-exp-at",
  source: "source",
  ratingValue: "rating-value",
  skipRating: "skip-rating",
  coupon: "coupon",
  userIp: "user_ip",
  selectedMode: "selected-mode",
  mode: "selected-mode!",
  prevFunnelPage: "prev-funnel-page",
  product: "product",
  addressSetByGoogle: "addressSetByGoogle",
  dfyVersion: "dfy-version",
  diyReturnPlan: "diy-return-plan",
  diyStoreData: "diy-store-data",
  ingestId: "ingest_id",
  competitor: "competitor",
  productFocus: "product-focus"
});

export const SECOND_IN_MS = 1000;
export const THREE_SECONDS_IN_MS = SECOND_IN_MS * 3;
export const TEN_SECONDS_IN_MS = SECOND_IN_MS * 10;
export const FIFTEEN_SECONDS_IN_MS = SECOND_IN_MS * 15;
export const THIRTY_SECONDS_IN_MS = SECOND_IN_MS * 30;
export const NINETY_SECONDS_IN_MS = SECOND_IN_MS * 90;

export const MINUTE_IN_MS = 1000 * 60;
export const TWO_MINUTES_IN_MS = MINUTE_IN_MS * 2;
export const THREE_MINUTES_IN_MS = MINUTE_IN_MS * 3;
export const FIVE_MINUTES_IN_MS = MINUTE_IN_MS * 5;
export const SIX_MINUTES_IN_MS = MINUTE_IN_MS * 6;
export const TEN_MINUTES_IN_MS = MINUTE_IN_MS * 10;
export const TWENTY_MINUTES_IN_MS = MINUTE_IN_MS * 20;
export const HOUR_IN_MS = MINUTE_IN_MS * 60;
export const DAY_IN_MS = HOUR_IN_MS * 24;
export const THREE_DAYS_IN_MS = DAY_IN_MS * 3;
export const FIVE_DAYS_IN_MS = DAY_IN_MS * 5;
export const WEEK_IN_MS = DAY_IN_MS * 7;
export const MONTH_IN_MS = DAY_IN_MS * 30;
export const TWO_MONTHS_IN_MS = MONTH_IN_MS * 2;
export const THREE_MONTHS_IN_MS = MONTH_IN_MS * 3;
export const YEAR_IN_MS = DAY_IN_MS * 365;

export const MINUTE_IN_SECONDS = 60;
export const TWO_MINUTES_IN_SECONDS = MINUTE_IN_SECONDS * 2;
export const THREE_MINUTES_IN_SECONDS = MINUTE_IN_SECONDS * 3;
export const FIVE_MINUTES_IN_SECONDS = MINUTE_IN_SECONDS * 5;
export const TEN_MINUTES_IN_SECONDS = MINUTE_IN_SECONDS * 10;
export const TWENTY_MINUTES_IN_SECONDS = MINUTE_IN_SECONDS * 20;
export const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60;
export const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;
export const THREE_DAYS_IN_SECONDS = DAY_IN_SECONDS * 3;
export const FIVE_DAYS_IN_SECONDS = DAY_IN_SECONDS * 5;
export const WEEK_DAYS_IN_SECONDS = DAY_IN_SECONDS * 7;
export const MONTH_IN_SECONDS = DAY_IN_SECONDS * 30;
export const TWO_MONTHS_IN_SECONDS = MONTH_IN_SECONDS * 2;
export const YEAR_IN_SECONDS = DAY_IN_SECONDS * 365;

export const POSTHOG_EXPERIMENTS = Object.freeze({
  DASHBOARD_BANNER: "ph-dashboard-banner",
  D2C_CHECKOUT: "ph-d2c-checkout",
  FUNNEL: "ph-a16-a31-a34-a36",
  A31_OFFER: "ph-a31-of45-of46"
});

export const POSTHOG_EXPERIMENTS_KEYS = Object.freeze({
  "ph-form-ssn": {
    LOCK_AND_ICONS: "lock-trust-icons",
    ICONS: "trust-icons",
    CONTROL: "control"
  },
  "ph-dashboard-banner": {
    CONTROL: "control",
    CARTOON: "1040-cartoon",
    REFUND_CALCULATOR: "refund-calculator",
    WINKING_WOMAN: "winking-woman"
  },
  "ph-d2c-checkout": {
    CONTROL: "control",
    POSITIVE: "positive",
    NEGATIVE: "negative"
  },
  "ph-a16-a31-a34-a36": {
    CONTROL: "control",
    A31: "a31",
    A34: "a34",
    A36: "a36"
  },
  "ph-a31-of45-of46": {
    CONTROL: "of45",
    OF46: "of46"
  }
});

export const assetsDomain = `https://assets.${getSecondLevelDomain()}`;

export const VIDEOS = Object.freeze({
  sam_ext_update_prep_return: `${assetsDomain}/videos/d918f270-ed69-4614-bf77-d1f171799a5e/HLS1/RVSD_6_Sam_01.m3u8`,
  allison_ext_update_prep_return: `${assetsDomain}/videos/8bea8e52-4307-4610-8670-98c073b87fc4/HLS1/RVSD_2_Allison_0102.m3u8`,

  //DIY videos
  sam_diy_prep_for_fs_short: `${assetsDomain}/videos/65b9a095-410d-4572-b709-934614cf0849/HLS1/RVSD_2_Sam_VD0206.m3u8`,
  allison_diy_prep_for_fs_short: `${assetsDomain}/videos/e25f29ee-b777-4350-b918-314fdacc4f27/HLS1/RVSD_2_Allison_VD0201.m3u8`,
  allison_diy_trial: `${assetsDomain}/videos/35a8d657-827e-4a18-bf9d-5768c40fe1f4/HLS1/Allison_vd202.m3u8`,
  sam_diy_common_mistakes: `${assetsDomain}/media/video/5-tax-mistakes.mp4`,

  //DFY videos
  sam_dfy_prep_short: `${assetsDomain}/videos/e597e210-32a7-48bc-91ff-d2a85ab2933d/HLS1/RVSD_1_Sam_0305.m3u8`,
  allison_alan_dfy_prep: `${assetsDomain}/videos/2d5a84b1-b22d-4b21-952d-308442ddac43/HLS1/RVSD_1_Allison_Alan_0801.m3u8`,
  sam_alan_dfy_prep: `${assetsDomain}/videos/7b300163-b913-4eef-8dd8-3a361484634d/HLS1/RVSD_1_Sam_Alan_0801.m3u8`,
  allison_dfy_prep: `${assetsDomain}/videos/3e6e3b97-be28-4da1-8d79-9c4f76c1b116/HLS1/Allison_0301.m3u8`,
  allison_dfy_trial: `${assetsDomain}/videos/887d5e99-a55b-46a3-9521-81dd46016d3d/HLS1/Allison_vd03.02.m3u8`,

  //DFY Business videos
  sam_dfy_prep_for_business_short: `${assetsDomain}/videos/5a8543a6-0495-4d2b-94c3-13cd7eac15b3/HLS1/RVSD_2_Sam_0603.m3u8`,
  sam_alan_dfy_prep_for_business: `${assetsDomain}/videos/094ad1fb-4ebe-4b81-b1f7-7f541becf27c/HLS1/RVSD_2_Sam_Alan_0701.m3u8`,
  allison_dfy_prep_for_business_short: `${assetsDomain}/videos/eab3a5fe-c430-44d9-be75-e8575a3185f0/HLS1/RVSD_2_Allison_0604.m3u8`,
  allison_alan_dfy_prep_for_business: `${assetsDomain}/videos/595a42e9-5685-4511-91e2-db0e2a6dac27/HLS1/RVSD_3_Allison_Alan_0701.m3u8`
});

interface GtmEvent {
  eventId: string;
  eventName: string;
  eventCode: string;
  g1ConversionLabel: string;
  g2ConversionLabel: string;
  itemPrice: number;
}

export const EVENTS: Readonly<Record<string, GtmEvent>> = Object.freeze({
  event_01: {
    eventId: "event_01",
    eventName: "sign_up",
    eventCode: "event_01",
    g1ConversionLabel: "zNetCLSE7NYBEPed-dAD",
    g2ConversionLabel: "FIupCIb5gpAZELHo8swD",
    itemPrice: 0
  },
  event_02: {
    eventId: "event_02",
    eventName: "button_hero",
    eventCode: "event_02",
    g1ConversionLabel: "Dq9oCNfBuZkDEPed-dAD",
    g2ConversionLabel: "-w0xCIn5gpAZELHo8swD",
    itemPrice: 0
  },
  event_03: {
    eventId: "event_03",
    eventName: "add_to_cart",
    eventCode: "event_03",
    g1ConversionLabel: "JoF-COrUs4oYEPed-dAD",
    g2ConversionLabel: "OlJkCOeJg5AZELHo8swD",
    itemPrice: 0
  },
  event_04: {
    eventId: "event_04",
    eventName: "?",
    eventCode: "event_04",
    g1ConversionLabel: "",
    g2ConversionLabel: "y5AICOmy7pcDELHo8swD",
    itemPrice: 0
  },
  event_05: {
    eventId: "event_05",
    eventName: "pre-pu:fs_no",
    eventCode: "event_05",
    g1ConversionLabel: "j6dDCPmOrJAZEPed-dAD",
    g2ConversionLabel: "wdZ-CPf1z5AZELHo8swD",
    itemPrice: 0
  },
  event_06: {
    eventId: "event_06",
    eventName: "ext_type_business",
    eventCode: "event_06",
    g1ConversionLabel: "kcLdCPyOrJAZEPed-dAD",
    g2ConversionLabel: "WBAlCLua1pAZELHo8swD",
    itemPrice: 0
  },
  event_07: {
    eventId: "event_07",
    eventName: "new_filing",
    eventCode: "event_07",
    g1ConversionLabel: "duL3CP-OrJAZEPed-dAD",
    g2ConversionLabel: "ooMXCP3Oz5AZELHo8swD",
    itemPrice: 0
  },
  event_08: {
    eventId: "event_08",
    eventName: "registration_step_1",
    eventCode: "event_08",
    g1ConversionLabel: "TrRtCIKPrJAZEPed-dAD",
    g2ConversionLabel: "nD5WCOWiyJAZELHo8swD",
    itemPrice: 0
  },
  event_09: {
    eventId: "event_09",
    eventName: "login",
    eventCode: "event_09",
    g1ConversionLabel: "Nh8rCNuI15AZEPed-dAD",
    g2ConversionLabel: "b_xbCM2fyJAZELHo8swD",
    itemPrice: 0
  },
  event_10: {
    eventId: "event_10",
    eventName: "print_and_mail_1",
    eventCode: "event_10",
    g1ConversionLabel: "ojgpCN6I15AZEPed-dAD",
    g2ConversionLabel: "fgb5COqbyJAZELHo8swD",
    itemPrice: 0
  },
  event_11: {
    eventId: "event_11",
    eventName: "cta_middle",
    eventCode: "event_11",
    g1ConversionLabel: "iBJRCMa8g5AZEPed-dAD",
    g2ConversionLabel: "5lE1COqJg5AZELHo8swD",
    itemPrice: 0
  },
  event_12: {
    eventId: "event_12",
    eventName: "post-pu:shield_no",
    eventCode: "event_12",
    g1ConversionLabel: "1QrKCOGI15AZEPed-dAD",
    g2ConversionLabel: "1W4RCNrH1ZAZELHo8swD",
    itemPrice: 0
  },
  event_13: {
    eventId: "event_13",
    eventName: "post-pu:fs_no",
    eventCode: "event_13",
    g1ConversionLabel: "yC94COSI15AZEPed-dAD",
    g2ConversionLabel: "aiygCIz4zpAZELHo8swD",
    itemPrice: 0
  },
  event_14: {
    eventId: "event_14",
    eventName: "file_diy_return",
    eventCode: "event_14",
    g1ConversionLabel: "TzQQCOeI15AZEPed-dAD",
    g2ConversionLabel: "GhMtCNCKyJAZELHo8swD",
    itemPrice: 0
  },
  event_15: {
    eventId: "event_15",
    eventName: "file_dfy_return",
    eventCode: "event_15",
    g1ConversionLabel: "MrIaCOqI15AZEPed-dAD",
    g2ConversionLabel: "Poa1CKT4zpAZELHo8swD",
    itemPrice: 0
  },
  event_16: {
    eventId: "event_16",
    eventName: "file_tax_extension",
    eventCode: "event_16",
    g1ConversionLabel: "uvZcCO2I15AZEPed-dAD",
    g2ConversionLabel: "CHowCOy-1ZAZELHo8swD",
    itemPrice: 0
  },
  event_17: {
    eventId: "event_17",
    eventName: "cta_bottom",
    eventCode: "event_17",
    g1ConversionLabel: "3YYaCMm8g5AZEPed-dAD",
    g2ConversionLabel: "ttHsCO2Jg5AZELHo8swD",
    itemPrice: 0
  },
  event_19: {
    eventId: "event_19",
    eventName: "ext_type_both",
    eventCode: "event_19",
    g1ConversionLabel: "OACvCPCI15AZEPed-dAD",
    g2ConversionLabel: "Db5ECMaz1ZAZELHo8swD",
    itemPrice: 0
  },
  event_20: {
    eventId: "event_20",
    eventName: "post-pu:dfy_no",
    eventCode: "event_20",
    g1ConversionLabel: "Uw5fCPOI15AZEPed-dAD",
    g2ConversionLabel: "tRaOCOb5x5AZELHo8swD",
    itemPrice: 0
  },
  event_21: {
    eventId: "event_21",
    eventName: "post-pu:taxprep_no",
    eventCode: "event_21",
    g1ConversionLabel: "cWQSCPaI15AZEPed-dAD",
    g2ConversionLabel: "N8vDCOX1x5AZELHo8swD",
    itemPrice: 0
  },
  event_22: {
    eventId: "event_22",
    eventName: "faq",
    eventCode: "event_22",
    g1ConversionLabel: "q8QsCPmI15AZEPed-dAD",
    g2ConversionLabel: "vJyiCPTlzpAZELHo8swD",
    itemPrice: 0
  },
  event_23: {
    eventId: "event_23",
    eventName: "extend_now",
    eventCode: "event_23",
    g1ConversionLabel: "Qtx2CPyI15AZEPed-dAD",
    g2ConversionLabel: "hgw-CMjjzpAZELHo8swD",
    itemPrice: 0
  },
  event_24: {
    eventId: "event_24",
    eventName: "file_tax_return",
    eventCode: "event_24",
    g1ConversionLabel: "PULJCP-I15AZEPed-dAD",
    g2ConversionLabel: "MLmKCNzfzpAZELHo8swD",
    itemPrice: 0
  },
  event_25: {
    eventId: "event_25",
    eventName: "ext_type_personal",
    eventCode: "event_25",
    g1ConversionLabel: "2k6dCIKJ15AZEPed-dAD",
    g2ConversionLabel: "Kic4CIntx5AZELHo8swD",
    itemPrice: 0
  },
  event_26: {
    eventId: "event_26",
    eventName: "cta_top",
    eventCode: "event_26",
    g1ConversionLabel: "DZmECM-8g5AZEPed-dAD",
    g2ConversionLabel: "kUg-CPOJg5AZELHo8swD",
    itemPrice: 0
  },
  event_27: {
    eventId: "event_27",
    eventName: "cta_float",
    eventCode: "event_27",
    g1ConversionLabel: "tb-2CNK8g5AZEPed-dAD",
    g2ConversionLabel: "XzMmCPaJg5AZELHo8swD",
    itemPrice: 0
  },
  event_28: {
    eventId: "event_28",
    eventName: "file_asap",
    eventCode: "event_28",
    g1ConversionLabel: "0s7KCIWJ15AZEPed-dAD",
    g2ConversionLabel: "vwjDCOKl1ZAZELHo8swD",
    itemPrice: 0
  },
  event_29: {
    eventId: "event_29",
    eventName: "file_1-3_months",
    eventCode: "event_29",
    g1ConversionLabel: "iM_YCIiJ15AZEPed-dAD",
    g2ConversionLabel: "p7mTCJ7azpAZELHo8swD",
    itemPrice: 0
  },
  event_30: {
    eventId: "event_30",
    eventName: "file_4+_months",
    eventCode: "event_30",
    g1ConversionLabel: "gmC7CIuJ15AZEPed-dAD",
    g2ConversionLabel: "ZmHQCOrZx5AZELHo8swD",
    itemPrice: 0
  },
  event_31: {
    eventId: "event_31",
    eventName: "file_diy",
    eventCode: "event_31",
    g1ConversionLabel: "XKO9CI6J15AZEPed-dAD",
    g2ConversionLabel: "qN2BCLHWx5AZELHo8swD",
    itemPrice: 0
  },
  event_32: {
    eventId: "event_32",
    eventName: "file_dfy",
    eventCode: "event_32",
    g1ConversionLabel: "ap6CCJGJ15AZEPed-dAD",
    g2ConversionLabel: "c6g_CPzQx5AZELHo8swD",
    itemPrice: 0
  },
  event_33: {
    eventId: "event_33",
    eventName: "cancel",
    eventCode: "event_33",
    g1ConversionLabel: "9d7BCPqZz6AZEPed-dAD",
    g2ConversionLabel: "pCIPCLz_zqAZELHo8swD",
    itemPrice: 0
  },
  event_34: {
    eventId: "event_34",
    eventName: "cancel",
    eventCode: "event_34",
    g1ConversionLabel: "FC7UCP2Zz6AZEPed-dAD",
    g2ConversionLabel: "h2GoCL__zqAZELHo8swD",
    itemPrice: 0
  }
});

export const DIY_TAG = "diy-2025";

export const PRODUCT_SPECS: Record<DiyPlans, Partial<DtoProduct>> = {
  [DiyPlans.Subscription]: {
    is_subscription: true,
    tags: [DIY_TAG]
  },
  [DiyPlans.OneTimePurchase]: {
    is_subscription: false,
    tags: [DIY_TAG]
  }
};

export const BRAND = import.meta.env.VITE_BRAND;

export const VALIDATION_MESSAGES_KEYS = Object.freeze({
  emailAlreadyExists: "email already exists",
  emailLoginNotPermitted: "login with email not permitted, please use username",
  emailLoginNotPermittedMessage:
    "Username is required for secure access, if you do not know your username please select Forgot Username",
  emailCouldNotBeFound: "email could not be found",
  emailOrUsernameNotFound: "invalid username or password combination",
  usernameAlreadyExists: "username is already taken",
  usernameNotFound: "username not found",
  usernameRequiredForSecureAccess: "Username is required for secure access",
  loginTooManyAttempts: "Too many login attempts. Account been locked for 15 minutes.",
  loginStatusCode429: "Request failed with status code 429"
});

export const NO_AUTH_HEADER = {
  headers: {
    "X-No-Auth": true
  }
};
