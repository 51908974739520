import { cookieNames } from "@/config/constants";
import type { DtoProduct, UtilsProductShort } from "@/services/api";
import { useCartStore } from "@/store/cart.store";
import { useAppStateStore } from "@/store/state.store";
import type { Filing } from "@/store/types";
import { FilingPriority } from "@/store/types";
import { getCookie } from "@/util/cookies";

export function findProduct(sku: string): DtoProduct | undefined {
  const appStateStore = useAppStateStore();
  const products: DtoProduct[] = appStateStore.settings.products ?? [];

  return products.find((product) => product.sku === sku);
}

export function findProductBySpecification(
  properties: Partial<DtoProduct>,
  excludedProperties?: Partial<DtoProduct>
): DtoProduct | UtilsProductShort | undefined {
  const appStateStore = useAppStateStore();
  const cartStore = useCartStore();

  let products: Array<DtoProduct | UtilsProductShort> = appStateStore.settings.products ?? [];

  if (!products.length) {
    products = cartStore.productsShort || [];
  }

  return products.find((product) => {
    const isPropertiesPassed = Object.keys(properties).every((key) => {
      if (Array.isArray(properties[key]) && Array.isArray(product[key])) {
        return properties[key].every((item) => product[key].includes(item));
      } else {
        return product[key] === properties[key];
      }
    });

    const isExcludedPropertiesPassed =
      !excludedProperties ||
      Object.keys(excludedProperties).every((key) => {
        if (Array.isArray(excludedProperties[key]) && Array.isArray(product[key])) {
          return excludedProperties[key].every((item) => !product[key].includes(item));
        } else {
          return product[key] !== excludedProperties[key];
        }
      });

    return isPropertiesPassed && isExcludedPropertiesPassed;
  });
}

export function getPosthogSessionStorageOverrides(): Record<string, string> {
  let sessionStorageOverrides: Record<string, string> = {};

  try {
    sessionStorageOverrides = JSON.parse(window.sessionStorage.getItem(cookieNames.posthogOverrides) ?? "{}");
  } catch {
    sessionStorageOverrides = {};
  }

  return sessionStorageOverrides;
}

export const checkFunnelOrA16 = (f: string): string => {
  // Funnel should always be of type  A16
  // if not return A16
  const funnelPattern = /^[A-Z]\d{2}$/;
  if (!funnelPattern.test(f)) {
    return "A16";
  }

  return f;
};

export function getFunnelFromClientStorage(): string | undefined {
  const funnelOverride = getCookie(cookieNames.funnel);
  if (!funnelOverride) {
    return undefined;
  }

  return checkFunnelOrA16(funnelOverride);
}

export function getPosthogSearchParamOverrides(searchParams: Record<string, string>): Record<string, string> {
  const overrideKeys = Object.keys(searchParams).filter((key) => key.startsWith("ph-"));
  const funnel = searchParams.funnel;

  if (!overrideKeys.length && !overrideKeys) {
    return {};
  }

  const overrides: Record<string, string> = {};
  overrideKeys.forEach((key) => {
    overrides[key] = searchParams[key];
  });

  if (funnel) {
    overrides.funnel = funnel;
  }

  return overrides;
}

export function unique<T>(item: T, index: number, array: T[]): boolean {
  return array.indexOf(item) === index;
}

export const filingComparator = (a: Filing, b: Filing): number => {
  const yearA = "year" in a ? Number(a.year) : "tax_year" in a ? a.tax_year : undefined;
  const yearB = "year" in b ? Number(b.year) : "tax_year" in b ? b.tax_year : undefined;

  if (yearA !== undefined && yearB !== undefined) {
    if (yearA > yearB) {
      return -1;
    } else if (yearA < yearB) {
      return 1;
    }
  }
  return 0;
};

export const sortFilings = (filings: Filing[]): Filing[] => {
  const highPriorityFilings = filings.filter(({ priority }) => priority === FilingPriority.High);
  const lowPriorityFilings = filings.filter(({ priority }) => priority === FilingPriority.Low);

  return [...highPriorityFilings.sort(filingComparator), ...lowPriorityFilings.sort(filingComparator)];
};

export const splitFullName = (fullName: string | undefined) => {
  if (!fullName) {
    return { firstName: "", lastName: "" };
  }

  const nameParts = fullName.split(" ");
  return {
    firstName: nameParts[0],
    lastName: nameParts.slice(1).join(" ")
  };
};
