import type { RawAxiosRequestConfig } from "axios";

import { Engine } from "@/config/constants";
import { useAuthStore } from "@/store/auth.store";
import { usePostHogStore } from "@/store/posthog.store";

import { getEngineFromConfig } from "./helpers";

export const getRequestConfigWithToken = async (): Promise<RawAxiosRequestConfig> => {
  const authStore = useAuthStore();

  await authStore.refreshToken();

  return {
    headers: {
      Authorization: `Bearer ${authStore.authToken}`
    }
  };
};

export const LogoutHlp = async (redirectToHome: boolean = true, customRedirectUrl: string | null = null) => {
  const isDev = import.meta.env.MODE !== "production";
  const authStore = useAuthStore();

  try {
    await authStore.logoutAndResetState();
  } finally {
    const postHogStore = usePostHogStore();
    postHogStore.reset();

    if (redirectToHome) {
      window.location.href = "/";
    }

    if (customRedirectUrl && customRedirectUrl !== "" && !window.location.href.includes(customRedirectUrl)) {
      window.location.href = customRedirectUrl;
    } else {
      let redirectURL = "/";

      if (getEngineFromConfig() === Engine.FileSmart && !isDev) {
        redirectURL = "https://filesmart.tax/logout";
      }

      window.location.href = redirectURL;
    }
  }
};
